;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"d3b9a1b06c00e234eb93433fab4a0f17ab1e416a"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/fem/d3b9a1b06c00e234eb93433fab4a0f17ab1e416a";import * as Sentry from '@sentry/nextjs'
import {getEnvironment} from 'sentry.utils'
import {env} from '~/config/env.mjs'
import {isProd} from '~/config/nodeEnv'

Sentry.init({
  dsn: env.NEXT_PUBLIC_SENTRY_DSN,
  debug: false,
  enabled: isProd,
  environment: getEnvironment(),
})
